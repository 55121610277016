<template>
  <div>
    <div
      class="site-blocks-cover inner-page overlay"
      style="background-image: url(images/hero_bg_2.jpg); background-size:cover"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 text-center">
            <h2 style="font-size:3rem;color:white;">
              <strong>{{Menu[name]}}</strong>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="ListText.length > 0"
      class="site-section text-card align-items-center justify-content-center"
      v-html="ListText"
    ></div>
    <div class="site-section">
      <div class="container">
        <div class="product-list">
          <router-link
            v-for="(l,index) in List"
            class="card1 is-pointer"
            @click.native="$scrollToTop"
            :to="l.link"
            :key="index"
          >
            <div class="card1-body">
              <div class="row">
                <div
                  class="col-12 col-md-4 side"
                  style="background-size:cover"
                  :style="GetCardStyle(l.key)"
                ></div>
                <div class="col-12 col-md-8">
                  <div class="category">
                    <h2 class="title">{{Menu[l.key]}}</h2>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p style="float:right">
                      <button class="btn">
                        <span class="icon-arrow-right"></span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

let requireContext = null;

function importAll(r) {
  let images = {};

  r.keys().map((item, index) => {
    let lastIndexOfDot = item.lastIndexOf(".");
    let itemName = item.substring(0, lastIndexOfDot);
    images[itemName.replace("./", "")] = r(item);
  });

  return images;
}

const createdContext = require.context(
  "../../public/images/prod/list",
  false,
  /\.(png|jpe?g|svg)$/
);

requireContext = createdContext;

export default {
  props: {
    name: String
  },
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang",
      MenuMap: "ProductMenuMap"
    }),
    Menu() {
      return this.LanguageMap.menu;
    },
    Breadcrumb() {
      let vm = this;
      let nameSplitted = this.name.split("-");
      let hierarchy = [];
      for (let i = 2; i <= nameSplitted.length; i++) {
        const elName = nameSplitted.slice(0, i).join("-");
        //hierarchy.push(`<a href="javascript:;">${vm.Menu[elName]}</a>`);
        hierarchy.push(
          `<router-link to="/${vm.Lang}/products/list/${elName}">${vm.Menu[elName]}</a>`
        );
      }

      return hierarchy.join(">");
    },
    List() {
      let vm = this;

      let nameSplitted = this.name.split("-");

      let mapList = [];

      let menu = JSON.parse(JSON.stringify(this.MenuMap));
      let map = {};

      for (let i = 2; i <= nameSplitted.length; i++) {
        const elName = nameSplitted.slice(0, i).join("-");
        if (i == 2) {
          map = menu[elName];
          if (map == undefined) {
            return [];
          }
        } else {
          let f = menu.filter(function(m) {
            return m[elName] != undefined;
          })[0];
          map = f[elName];
        }
        if (map.type == "list") {
          menu = map.list;
        }
      }

      if (map != null) {
        if (map.type == "list") {
          mapList = map.list;
        }
      }

      return mapList.length == 0
        ? mapList
        : mapList.map(function(m) {
            let obj = m[Object.keys(m)[0]];
            let key = Object.keys(m)[0];
            let params = { lang: vm.Lang };
            if (obj.type == "list") {
              params["list"] = key;
            } else {
              params["product"] = key;
            }
            return {
              key: key,
              type: obj.type,
              link: {
                name: obj.type == "list" ? "prod_list" : "prod",
                params: params
              }
            };
          });
    },
    Images() {
      let vm = this;
      let images = importAll(createdContext);
      let keys = Object.keys(images).filter(x => x.startsWith(vm.name));
      let returnedImages = {};
      for (const key in keys) {
        if (keys.hasOwnProperty(key)) {
          const imgKey = keys[key];
          returnedImages[imgKey] = {
            key: key,
            name: imgKey,
            ref: images[imgKey]
          };
        }
      }
      return returnedImages;
    },
    ListText() {
      if (this.LanguageMap.products.list_texts == undefined) {
        return "";
      }
      return this.LanguageMap.products.list_texts[this.name] == undefined
        ? ""
        : this.LanguageMap.products.list_texts[this.name];
    }
  },
  methods: {
    GetCardStyle(key) {
      if (this.Images[key] != undefined) {
        if (this.Images[key].ref != undefined)
          return { backgroundImage: "url(" + this.Images[key].ref + ")" };
      }
      return { height: "0px" };
    }
  }
};
</script>

<style scoped lang="scss">
.product-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card1 {
  border-radius: 0%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  background: #f5f5f5;
  color: black;
  text-align: left;
  margin: 1.2rem;
  margin-top: 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 15px;
  transition: 0.8s;
  .card1-body {
    padding: 0%;
    .side {
      // border-right: 1px solid rgba(0, 0, 0, 0.2);
      transition: 0.4s;
    }

    .category {
      padding: 4em 0;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.text-card {
  margin: 2em 5em;
  border: 1px solid gray;
  border-radius: 0.25em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background: #f3f3f3;
  font-size: 2em;
}

@media only screen and (max-width: 992px) {
  .side {
    height: 350px;
  }
  .image-text {
    height: auto;
  }
  .text-card {
    margin: 0.25em;
    font-size: 1em;
  }
}

@media only screen and (min-width: 993px) {
  .image-text {
    height: 450px;
  }
  .site-section {
    padding: 2em 0;
  }
  .text-card {
    margin: 2em 5em;
    font-size: 2em;
  }
}
</style>