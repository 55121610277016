<template>
  <div>
    <div
      class="site-blocks-cover inner-page overlay"
      style="background-image: url(images/hero_bg_2.jpg);"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 text-center">
            <h1>
              <strong>{{Contact.header}}</strong>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="site-section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8 mb-5">
            <form action="javascript:;" class="p-5 bg-white">
              <div class="row form-group">
                <div class="col-md-12 mb-3 mb-md-0">
                  <label class="font-weight-bold" for="fullname">{{Contact.form.fullname_text}}</label>
                  <input
                    type="text"
                    id="fullname"
                    class="form-control"
                    :placeholder="Contact.form.fullname_text"
                    v-model="contact.fullname"
                  />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="font-weight-bold" for="email">{{Contact.form.email_text}}</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    :placeholder="Contact.form.email_text"
                    v-model="contact.email"
                  />
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-12">
                  <label class="font-weight-bold" for="message">{{Contact.form.message_text}}</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    class="form-control"
                    :placeholder="Contact.form.message_placeholder"
                    v-model="contact.message"
                  ></textarea>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-12">
                  <input type="submit" :value="Contact.form.sendbtn_text" class="btn btn-primary" @click="SendEmail" />
                </div>
              </div>
            </form>
          </div>

          <div class="col-lg-4">
            <div class="p-4 mb-3 bg-white">
              <h3 class="h5 text-black mb-3">{{Contact.contact_info.text}}</h3>
              <p class="mb-0 font-weight-bold">{{Contact.contact_info.address_text}}</p>
              <p class="mb-4">{{Globals.address}}</p>

              <p class="mb-0 font-weight-bold">{{Contact.contact_info.phone_text}}</p>
              <p class="mb-4">
                <a :href="('tel:'+Globals.phoneNumber)">{{Globals.phoneNumber}}</a>
              </p>

              <p class="mb-0 font-weight-bold">{{Contact.contact_info.email_text}}</p>
              <p class="mb-0">
                <a :href="('mailto:'+Globals['e-mail'])">{{Globals["e-mail"]}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-12 col-lg-12 mb-5 mb-lg-0">
      <div class="widget clearfix">
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2055.144161347187!2d29.396553554192398!3d40.7946493133202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9447062900ffe94b!2sG%C3%BCrteksan!5e0!3m2!1str!2str!4v1561302503543!5m2!1str!2str"
            width="100%"
            height="400"
            frameborder="0"
            style="border:0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <!-- end clearfix -->
    </div>

    <div class="promo py-5 bg-primary" data-aos="fade">
      <div class="container text-center">
        <h2 class="d-block mb-0 font-weight-light text-white">
          <a href="javascript:;" class="text-white d-block">{{Contact.visitouroffice_message}}</a>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";


export default {
  data(){
    return {
      contact: {
        fullname:"",
        email:"",
        message: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang",
      Globals: "Globals"
    }),
    Menu() {
      return this.LanguageMap.menu;
    },
    Contact() {
      return this.LanguageMap.contact;
    }
  },
  methods:{
    SendEmail(){
      let vm = this;
      
      let warningText = "";

      if(vm.contact.fullname.length > 100){
        warningText += "İsim çok uzun olamaz. ";
      }

      if(vm.contact.email.length > 256){
        warningText += "Email adresi çok uzun olamaz. ";
      }

      if(vm.contact.fullname.length >= 2048){
        warningText += "Mesajınız en fazla 2048 karakter olabilir.";
      }

      if(warningText.length > 0){
        vm.$swal(warningText);
        return;
      }

      $.get(`/sendmessage/${vm.contact.fullname}/${vm.contact.email}/${vm.contact.message}`,
      function( data ) {
        if(data.result){
          vm.$swal("Mesajınız gönderildi.");
          vm.contact.fullname = "";
          vm.contact.email = "";
          vm.contact.message = "";
        }
        else{
          vm.$swal('Mesaj gönderirken bir sorun oluştu.');
        }
      })

    }
  }
};
</script>