<template>
  <keep-alive>
    <component :is="VisibleComponent"></component>
  </keep-alive>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import CorporateAbout from "@/components/CorporateAbout.vue";
import CorporateVisionMission from "@/components/CorporateVisionMission.vue";
import CorporateQuality from "@/components/CorporateQuality.vue";
import CorporateBusinessInfo from "@/components/CorporateBusinessInfo.vue";

export default {
  name: "corporate",
  components: {
    CorporateAbout,
    CorporateBusinessInfo,
    CorporateQuality,
    CorporateVisionMission
  },
  data() {
    return {};
  },
  computed: {
    VisibleComponent() {
      let sp = this.$route.params.subpage;
      if (sp == "about") return "CorporateAbout";
      if (sp == "vision-mission") return "CorporateVisionMission";
      if (sp == "quality") return "CorporateQuality";
      if (sp == "info") return "CorporateBusinessInfo";
    }
  },
  methods: {},
  mounted() {}
};
</script>
