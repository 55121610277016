import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import langMap from "@/data/languageMap.json";
const defaultLanguage = "tr";

const initializeArrayWithRangeRight = (end, start = 0, step = 1) =>
  Array.from({
    length: Math.ceil((end + 1 - start) / step)
  }).map(
    (v, i, arr) => (arr.length - i - 1) * step + start
  );

export default new Vuex.Store({
  state: {
    currentLanguage: defaultLanguage
  },
  getters: {
    LanguageMap(state) {
      return langMap[state.currentLanguage];
    },
    Lang(state) {
      return state.currentLanguage;
    },
    Globals(state) {
      return langMap[state.currentLanguage].globals;
    },
    ProductMenuMap(state) {
      return langMap["productMenuMap"];
    }
  },
  mutations: {
    setLanguage(state, lang) {
      state.currentLanguage = lang;
    }
  },
  actions: {

  }
})