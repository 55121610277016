<template>
  <keep-alive>
    <component :is="Component" v-bind="Props"></component>
  </keep-alive>
</template>

<script>
import Product from "@/components/Product.vue";
import ProductList from "@/components/ProductList.vue";

export default {
  components: {
    Product,
    ProductList
  },
  computed: {
    IsList() {
      if (this.$route.params.list != undefined) {
        return this.$route.params.list.length > 0;
      }
      return false;
    },
    IsProduct() {
      if (this.$route.params.product != undefined) {
        return this.$route.params.product.length > 0;
      }
      return false;
    },
    ListName() {
      return this.IsList ? this.$route.params.list : "";
    },
    ProductName() {
      return this.IsProduct ? this.$route.params.product : "";
    },
    Props() {
      return { name: this.IsList ? this.ListName : this.ProductName };
    },
    Component() {
      return this.IsList ? "ProductList" : "Product";
    }
  }
};
</script>
