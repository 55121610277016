<template>
  <div>
    <div
      class="site-blocks-cover inner-page overlay"
      style="background-image: url(images/hero_bg_2.jpg);background-size:cover;"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 text-center">
            <h2 style="font-size:3rem;color:white;">
              <strong>{{Menu[name]}}</strong>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="ProductText.length > 0"
      class="site-section text-card align-items-center justify-content-center"
      v-html="ProductText"
    ></div>
    <div class="site-section">
      <div class="row align-items-stretch image-card" v-for="img in Images" :key="img.key">
        <div
          v-if="img.key % 2 == 0"
          @click="ShowLightBox(img.key)"
          class="col-lg-6 mb-5 mb-lg-0 bg-image bg-md-height zoom is-pointer"
          :style="{backgroundImage: 'url('+ img.ref +')', backgroundSize:'cover', minHeight:'350px'}"
        ></div>
        <div class="col-lg-6 h-100">
          <div class="row row-item">
            <div
              class="col-md-12 mb-5 mb-md-0 image-text d-flex justify-content-center align-items-center"
              v-show="ImageTexts[img.name] != null ? ImageTexts[img.name].length > 0 ? true : false: false"
            >
              <h1>{{ImageTexts[img.name]}}</h1>
            </div>
          </div>
        </div>
        <div
          v-if="img.key % 2 == 1"
          @click="ShowLightBox(img.key)"
          class="col-lg-6 mb-5 mb-lg-0 bg-image bg-md-height zoom is-pointer"
          :style="{backgroundImage: 'url('+ img.ref +')', backgroundSize:'cover', minHeight:'350px'}"
        ></div>
      </div>
    </div>

    <LightBox
      ref="lightBox"
      :images="ImagesForLightBox"
      :showCaption="true"
      :showLightBox="showLightBox"
    ></LightBox>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

import Vue from "vue";
import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad);

import LightBox from "vue-image-lightbox";

let requireContext = null;

function importAll(r) {
  let images = {};

  r.keys().map((item, index) => {
    let lastIndexOfDot = item.lastIndexOf(".");
    let itemName = item.substring(0, lastIndexOfDot);
    images[itemName.replace("./", "")] = r(item);
  });

  return images;
}

const createdContext = require.context(
  "../../public/images/prod",
  false,
  /\.(png|jpe?g|svg)$/
);

requireContext = createdContext;

export default {
  components: {
    LightBox
  },
  props: {
    name: String
  },
  data() {
    return {
      showLightBox: false,
      startAt: 0
    };
  },
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang"
    }),
    Menu() {
      return this.LanguageMap.menu;
    },
    Images() {
      let vm = this;
      let images = importAll(createdContext);
      let keys = Object.keys(images).filter(x => x.startsWith(vm.name));
      let returnedImages = [];
      for (const key in keys) {
        if (keys.hasOwnProperty(key)) {
          const imgKey = keys[key];
          returnedImages.push({ key: key, name: imgKey, ref: images[imgKey] });
        }
      }
      return returnedImages;
    },
    ImagesForLightBox() {
      let vm = this;
      return this.Images.map(function(m) {
        return {
          thumb: m.ref,
          src: m.ref,
          caption: vm.ImageTexts[m.name], // Optional
          srcset: ""
        };
      });
    },
    ProductText() {
      if (this.LanguageMap.products.product_texts == undefined) {
        return "";
      }

      return this.LanguageMap.products.product_texts[this.name] == undefined
        ? ""
        : this.LanguageMap.products.product_texts[this.name];
    },
    ImageTexts() {
      return this.LanguageMap.products.image_texts == undefined
        ? {}
        : this.LanguageMap.products.image_texts;
    }
  },
  methods: {
    ShowLightBox(startAt) {
      this.$refs.lightBox.showImage(startAt);
    }
  }
};
</script>

<style scoped lang="scss">
.zoom {
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.02);
  }
}

.text-card {
  margin: 2em 5em;
  border: 1px solid gray;
  border-radius: 0.25em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background: #f3f3f3;
  font-size: 2em;
}

.image-card {
  margin: 2em 5em;
  border: 1px solid gray;
  border-radius: 0.25em;
}

.image-text {
  height: 450px;
}

@media only screen and (max-width: 992px) {
  .image-text {
    height: auto;
  }
  .text-card {
    margin: 0.25em;
  }
  .image-card {
    margin: 3em 0.25em;
  }
}
@media only screen and (min-width: 993px) {
  .image-text {
    height: 450px;
  }
  .text-card {
    margin: 2em 5em;
  }
  .image-card {
    margin: 2em 5em;
  }
}

@media (min-width: 768px) {
  .site-section {
    padding: 2em 0;
  }
}
</style>