<template>
  <div>
    <div
      class="site-blocks-cover inner-page overlay"
      style="background-image: url(images/hero_bg_2.jpg);"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 text-center">
            <h1>
              <strong>{{Menu["references"]}}</strong>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="image-container">
      <div v-for="(i,ind) in Images" class="image-element">
        <img :src="i.ref" height="80" width="190" />
        {{i.name}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

function importAll(r) {
  let images = {};

  r.keys().map((item, index) => {
    let lastIndexOfDot = item.lastIndexOf(".");
    let itemName = item.substring(0, lastIndexOfDot);
    images[itemName.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../public/images/references", false, /\.(png|jpe?g|svg)$/)
);

export default {
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang"
    }),
    Images() {
      return Object.keys(images)
        .sort()
        .map(x => {
          return { name: x, ref: images[x] };
        });
      //.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
    },
    Menu() {
      return this.LanguageMap.menu;
    }
  }
};
</script>

<style>
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.image-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgb(194, 194, 194);
  margin: 10px 5px;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 200px;
  height: 120px;
}
</style>