import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Corporate from './views/Corporate.vue'
import Products from './views/Products.vue'
import Videos from './views/Videos.vue'
import References from './views/References.vue'
import Contact from './views/Contact.vue'

Vue.use(Router)

const router = new Router({
  //mode: "history",
  routes: [{
      path: '/:lang',
      name: 'home',
      component: Home,
    },
    {
      path: '/:lang/corporate/:subpage?',
      name: 'corp',
      component: Corporate,
    },
    {
      path: '/:lang/products/product/:product?',
      name: 'prod',
      component: Products,
    },
    {
      path: '/:lang/products/list/:list?',
      name: 'prod_list',
      component: Products
    },
    {
      path: '/:lang/videos',
      name: 'videos',
      component: Videos
    },
    {
      path: '/:lang/references',
      name: 'references',
      component: References
    },
    {
      path: '/:lang/contact',
      name: 'contact',
      component: Contact
    }
  ]
})

router.afterEach((to, from) => {
  // console.log("to", to);
  // console.log("from", from);
  let value = {
    name: to.name,
    path: to.path,
    params: to.params
  };
  window.sessionStorage.setItem("page", JSON.stringify(value));
});

export default router;