<template>
  <div>
    <div
      class="site-blocks-cover inner-page overlay"
      style="background-image: url(images/hero_bg_2.jpg);"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 text-center">
            <h1>
              <strong>{{Menu["corporate-info"]}}</strong>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="site-section section-about">
      <div class="container">
        <div class="row mb-5 justify-content-center" v-if="(Info.header != '')">
          <div class="col-lg-8 col-md-12 text-center">
            <h2 class="display-4 text-black mb-5">{{Info.header}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 pr-md-5 text-left mb-5">
            <!-- <div v-html="Info.text" style="font-size:x-large"></div> -->
            <div style="font-size:x-large" v-show="!IsMobileSize">
              <div class="row">
                <div class="col-md-3">
                  <u>
                    <i>{{Info.table_header_info}}</i>
                  </u>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-8">
                  <u>
                    <i>{{Info.table_header_desc}}</i>
                  </u>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_title}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">Gürteksan Makina San. ve Tic. Ltd. Şti.</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_nace}}</div>
                <div class="col-md-1"></div>
                <div class="col-md-8">28.99.90</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_traderegno}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">24110</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_chamindregno}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">28903</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_sgk}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">228990202111468504102-70/000</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_phone}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">+90 262 777 00 62</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_address}}</div>
                <div class="col-md-1">:</div>
                <div
                  class="col-md-8"
                >Köşklüçeşme Mah. Yeni Bağdat Cad. No:126 41400 Gebze / Kocaeli / Türkiye</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_web}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">www.gurteksan.com</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_email}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">info@gurteksan.com</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_taxoffice}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">İlyasbey</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_taxno}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">4490290059</div>
              </div>
              <div class="row">
                <div class="col-md-3">{{Info.table_body_mersisno}}</div>
                <div class="col-md-1">:</div>
                <div class="col-md-8">0449029005900013</div>
              </div>
            </div>
            <div style="font-size:medium" v-show="IsMobileSize">
              <div class="row">
                <div class="col-5">
                  <u>
                    <i>{{Info.table_header_info}}</i>
                  </u>
                </div>
                <div class="col-7">
                  <u>
                    <i>{{Info.table_header_desc}}</i>
                  </u>
                </div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_title}}:</div>
                <div class="col-7">Gürteksan Makina San. ve Tic. Ltd. Şti.</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_nace}}</div>
                <div class="col-7">28.99.90</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_traderegno}}</div>
                <div class="col-7">24110</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_chamindregno}}</div>
                <div class="col-7">28903</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_sgk}}</div>
                <div class="col-7">228990202111468504102-70/000</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_phone}}</div>
                <div class="col-7">+90 262 777 00 62</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_address}}</div>
                <div
                  class="col-7"
                >{{Globals.address}}</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_web}}</div>
                <div class="col-7">www.gurteksan.com</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_email}}</div>
                <div class="col-7">info@gurteksan.com</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_taxoffice}}</div>
                <div class="col-7">İlyasbey</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_taxno}}</div>
                <div class="col-7">4490290059</div>
              </div>
              <div class="row">
                <div class="col-5">{{Info.table_body_mersisno}}</div>
                <div class="col-7">0449029005900013</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CorporateBusinessInfo",
  props: {},
  data() {
    return {
      size: 0
    };
  },
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang",
      Globals: "Globals"
    }),
    Menu() {
      return this.LanguageMap.menu;
    },
    Info() {
      return this.LanguageMap.corporate.corporate_business_info;
    },
    IsMobileSize() {
      return this.size < 768;
    }
  },
  mounted() {
    let vm = this;
    window.onresize = function() {
      vm.size = window.innerWidth;
    };
  }
};
</script>

