<template>
  <div>
    <div class="row">
      <div v-for="(video, index) in VideoList" :key="index" class="col-12 col-md-6 mb-5">
        <LazyYoutubeVideo :url="video.url" :previewImageSize="video.previewImageSize" />
      </div>
    </div>
  </div>
</template>

<script>
import LazyYoutubeVideo from "vue-lazy-youtube-video";

export default {
  components: {
    LazyYoutubeVideo
  },
  data() {
    return {};
  },
  computed: {
    VideoList() {
      return [
        {
          url: "https://www.youtube.com/watch?v=4LAPsDR7XKg",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=izvzvREvEsI",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=twD8bbMl0-k",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=BMC1UFXIu2w",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=hRBOa1sweUk",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=XKmTT6ADb6Y",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=jVUkmkx8YiQ",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=7xixkfR5z9k",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=Q5cKhIb3k80",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=SDS--Ss1W_Y",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=a2vzfYf-QSQ",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=4QxmMyMf2_o",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=KJmC9QJ8XOM",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=DD7Kb-IX254",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=PKH73Jbch5w",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=7nG9_5KIAJM",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=CE7qHc_IfQU",
          previewImageSize: "hqdefault"
        },
        {
          url: "https://www.youtube.com/watch?v=KbjDMmRFotM",
          previewImageSize: "hqdefault"
        }

        
      ];
    }
  }
};
</script>
<style scoped lang="scss">
</style>