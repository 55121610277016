<template>
  <div>
    <div
      class="site-blocks-cover inner-page overlay"
      style="background-image: url(images/hero_bg_2.jpg);"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 text-center">
            <h1>
              <strong>{{Menu["corporate-quality"]}}</strong>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="site-section section-about">
      <div class="container">
        <div class="row mb-5 justify-content-center" v-if="(Quality.header != '')">
          <div class="col-lg-8 col-md-12 text-center">
            <h2 class="display-4 text-black mb-5">{{Quality.header}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 pr-md-5 text-left mb-5">
            <div v-html="Quality.text" style="font-size:x-large"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CorporateQuality",
  props: {},
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang"
    }),
    Menu() {
      return this.LanguageMap.menu;
    },
    Quality() {
      return this.LanguageMap.corporate.corporate_quality;
    }
  }
};
</script>

