const main = function ($) {

	"use strict";

	AOS.init({
		duration: 1000,
		easing: 'slide',
		once: false
	});

	var sitePlusMinus = function () {
		$('.js-btn-minus').on('click', function (e) {
			e.preventDefault();
			if ($(this).closest('.input-group').find('.form-control').val() != 0) {
				$(this).closest('.input-group').find('.form-control').val(parseInt($(this).closest('.input-group').find('.form-control').val()) - 1);
			} else {
				$(this).closest('.input-group').find('.form-control').val(parseInt(0));
			}
		});
		$('.js-btn-plus').on('click', function (e) {
			e.preventDefault();
			$(this).closest('.input-group').find('.form-control').val(parseInt($(this).closest('.input-group').find('.form-control').val()) + 1);
		});
	};
	sitePlusMinus();


	var siteSliderRange = function () {
		$("#slider-range").slider({
			range: true,
			min: 0,
			max: 500,
			values: [75, 300],
			slide: function (event, ui) {
				$("#amount").val("$" + ui.values[0] + " - $" + ui.values[1]);
			}
		});
		$("#amount").val("$" + $("#slider-range").slider("values", 0) +
			" - $" + $("#slider-range").slider("values", 1));
	};
	//siteSliderRange();


	var siteMagnificPopup = function () {
		$('.image-popup').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			closeBtnInside: false,
			fixedContentPos: true,
			mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			},
			image: {
				verticalFit: true
			},
			zoom: {
				enabled: true,
				duration: 300 // don't foget to change the duration also in CSS
			}
		});

		$('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
			disableOn: 700,
			type: 'iframe',
			mainClass: 'mfp-fade',
			removalDelay: 160,
			preloader: false,

			fixedContentPos: false
		});
	};
	//siteMagnificPopup();


	var siteCarousel = function () {
		if ($('.nonloop-block-13').length > 0) {
			$('.nonloop-block-13').owlCarousel({
				center: false,
				items: 1,
				loop: true,
				stagePadding: 0,
				margin: 20,
				nav: false,
				dots: true,
				autoHeight: true,
				navText: ['<span class="icon-arrow_back">', '<span class="icon-arrow_forward">'],
				responsive: {
					600: {
						margin: 20,
						stagePadding: 0,
						items: 1
					},
					1000: {
						margin: 20,
						stagePadding: 0,
						items: 2
					},
					1200: {
						margin: 20,
						stagePadding: 0,
						items: 2
					}
				}
			});
		}

		if ($('.slide-one-item').length > 0) {
			$('.slide-one-item').owlCarousel({
				center: false,
				items: 2,
				loop: true,
				stagePadding: 0,
				margin: 0,
				autoplay: true,
				autoplayTimeout: 10000,
				pauseOnHover: false,
				nav: true,
				slideTransition: 'ease',
				dots: false,
				//autoHeight: true,
				responsive: {
					0: {
						items: 1,
						center: true,
					},
					768: {
						items: 2,
						center: false,
					}

				},
				navText: ['<a class="btn"><span class="back icon-arrow_back"></a>', '<a class="btn"><span class="next icon-arrow_forward"></a>']
			});


		}
	};
	siteCarousel();

	var siteStellar = function () {
		$(window).stellar({
			responsive: false,
			parallaxBackgrounds: true,
			parallaxElements: true,
			horizontalScrolling: false,
			hideDistantElements: false,
			scrollProperty: 'scroll'
		});
	};
	//siteStellar();

	var siteCountDown = function () {

		if ($('#date-countdown').length > 0) {
			$('#date-countdown').countdown('2020/10/10', function (event) {
				var $this = $(this).html(event.strftime('' +
					'<span class="countdown-block"><span class="label">%w</span> weeks </span>' +
					'<span class="countdown-block"><span class="label">%d</span> days </span>' +
					'<span class="countdown-block"><span class="label">%H</span> hr </span>' +
					'<span class="countdown-block"><span class="label">%M</span> min </span>' +
					'<span class="countdown-block"><span class="label">%S</span> sec</span>'));
			});
		}

	};
	siteCountDown();

	var siteDatePicker = function () {

		if ($('.datepicker').length > 0) {
			$('.datepicker').datepicker();
		}

	};
	siteDatePicker();

}

export default main;