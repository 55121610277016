<template>
  <div class="site-wrap">
    <div class="site-mobile-menu">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>
    <!-- .site-mobile-menu -->

    <div class="site-navbar-wrap bg-white" style="position: fixed;top: 0;width: 100%;">
      <div class="site-navbar-top">
        <div class="container py-2">
          <div class="row align-items-center">
            <div class="col-3">
              <!-- <a href="javascript:;" class="p-2 pl-0">
                <span class="icon-twitter"></span>
              </a>-->
              <a href="https://www.facebook.com/gurteksan/" class="p-2 pl-0">
                <span class="icon-facebook"></span>
              </a>
              <a href="https://www.linkedin.com/company/g%C3%BCrteksan-makina/" class="p-2 pl-0">
                <span class="icon-linkedin"></span>
              </a>
              <!-- <a href="javascript:;" class="p-2 pl-0">
                <span class="icon-instagram"></span>
              </a>-->
            </div>
            <div class="col-9">
              <div class="d-flex ml-auto">
                <a
                  :href="('tel:' + Globals.phoneNumber)"
                  class="d-flex align-items-center ml-auto mr-4"
                >
                  <span class="icon-phone mr-2"></span>
                  <span class="d-none d-md-inline-block">{{Globals.phoneNumber}}</span>
                </a>
                <a :href="('mailto:' + Globals['e-mail'])" class="d-flex align-items-center mr-4">
                  <span class="icon-envelope mr-2"></span>
                  <span class="d-none d-md-inline-block">{{Globals["e-mail"]}}</span>
                </a>
                <a href="#" class="d-flex align-items-center"><span class="icon-globe"></span></a>
                <select class="lang-select" v-model="selectedLanguage">
                  <option value="tr">Türkçe</option>
                  <option value="en">English</option>
                </select>
                <!-- <span
                  v-on:click="changeLanguage('en')"
                  class="flag-icon flag-icon-gb-eng flag-icon-medium is-pointer"
                ></span>
                <span
                  v-on:click="changeLanguage('tr')"
                  class="flag-icon flag-icon-tr flag-icon-medium is-pointer"
                ></span>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="site-navbar bg-light">
        <div class="container py-1">
          <div class="row align-items-center">
            <div class="col-2" style="z-index:10;">
              <h2 class="mb-0 site-logo">
                <router-link :to="{name:'home', lang: Lang}">
                  <img class="firm-logo" src="../public/images/logo.png" />
                </router-link>
              </h2>
            </div>
            <div class="col-10">
              <nav class="site-navigation text-right" role="navigation">
                <div class="container">
                  <div class="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                    <a href="javascript:;" class="site-menu-toggle js-menu-toggle text-black">
                      <span class="icon-menu h3"></span>
                    </a>
                  </div>

                  <ul class="site-menu js-clone-nav d-none d-lg-block">
                    <!-- <li class="active">
                      <router-link :to="{ name :'home', params: {lang:Lang} }">{{Menu.home}}</router-link>
                    </li> -->
                    <li class="has-children">
                      <router-link
                        :to="{ name :'corp', params: {lang:Lang,subpage:'about'} }"
                      >{{Menu.corporate}}</router-link>
                      <ul class="dropdown arrow-top">
                        <li>
                          <router-link
                            :to="{ name :'corp', params: {lang:Lang,subpage:'about'} }"
                          >{{Menu["corporate-about"]}}</router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name :'corp', params: {lang:Lang,subpage:'vision-mission'} }"
                          >{{Menu["corporate-vision-mission"]}}</router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name :'corp', params: {lang:Lang,subpage:'quality'} }"
                          >{{Menu["corporate-quality"]}}</router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name :'corp', params: {lang:Lang,subpage:'info'} }"
                          >{{Menu["corporate-info"]}}</router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-children">
                      <a>{{Menu.products}}</a>
                      <ul class="dropdown arrow-top">
                        <li class="has-children">
                          <!-- <a>{{Menu["products-metaldrumproduction"]}}</a> -->
                          <router-link
                            @click.native="$scrollToTop"
                            :to="{ name :'prod_list', params: {lang:Lang, list:'products-metaldrumproduction'} }"
                          >{{Menu["products-metaldrumproduction"]}}</router-link>
                          <ul class="dropdown">
                            <!-- <li class="has-children">
                              <a>{{Menu["products-metaldrumproduction-cylindir"]}}</a>
                              <ul class="dropdown">
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-cylindir-length'} }"
                                  >{{Menu["products-metaldrumproduction-cylindir-length"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-cylindir-cover'} }"
                                  >{{Menu["products-metaldrumproduction-cylindir-cover"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-cylindir-body'} }"
                                  >{{Menu["products-metaldrumproduction-cylindir-body"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-cylindir-paint'} }"
                                  >{{Menu["products-metaldrumproduction-cylindir-paint"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-cylindir-shipment'} }"
                                  >{{Menu["products-metaldrumproduction-cylindir-shipment"]}}</router-link>
                                </li>
                              </ul>
                            </li>-->
                            <li>
                              <!-- <a>{{Menu["products-metaldrumproduction-cylindir"]}}</a> -->
                              <router-link
                                @click.native="$scrollToTop"
                                :to="{ name :'prod_list', params: {lang:Lang, list:'products-metaldrumproduction-cylindir'} }"
                              >{{Menu["products-metaldrumproduction-cylindir"]}}</router-link>
                            </li>
                            <!-- <li class="has-children">
                              <a>{{Menu["products-metaldrumproduction-conic"]}}</a>
                              <ul class="dropdown">
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-conic-length'} }"
                                  >{{Menu["products-metaldrumproduction-conic-length"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-conic-cover'} }"
                                  >{{Menu["products-metaldrumproduction-conic-cover"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-conic-body'} }"
                                  >{{Menu["products-metaldrumproduction-conic-body"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-conic-paint'} }"
                                  >{{Menu["products-metaldrumproduction-conic-paint"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-conic-shipment'} }"
                                  >{{Menu["products-metaldrumproduction-conic-shipment"]}}</router-link>
                                </li>
                              </ul>
                            </li>-->
                            <li>
                              <!-- <a>{{Menu["products-metaldrumproduction-conic"]}}</a> -->
                              <router-link
                                @click.native="$scrollToTop"
                                :to="{ name :'prod_list', params: {lang:Lang, list:'products-metaldrumproduction-conic'} }"
                              >{{Menu["products-metaldrumproduction-conic"]}}</router-link>
                            </li>
                            <!-- <li class="has-children">
                              <a>{{Menu["products-metaldrumproduction-bidon"]}}</a>
                              <ul class="dropdown">
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-bidon-length'} }"
                                  >{{Menu["products-metaldrumproduction-bidon-length"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-bidon-cover'} }"
                                  >{{Menu["products-metaldrumproduction-bidon-cover"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-bidon-body'} }"
                                  >{{Menu["products-metaldrumproduction-bidon-body"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-bidon-paint'} }"
                                  >{{Menu["products-metaldrumproduction-bidon-paint"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-bidon-shipment'} }"
                                  >{{Menu["products-metaldrumproduction-bidon-shipment"]}}</router-link>
                                </li>
                              </ul>
                            </li>-->
                            <li>
                              <!-- <a>{{Menu["products-metaldrumproduction-bidon"]}}</a> -->
                              <router-link
                                @click.native="$scrollToTop"
                                :to="{ name :'prod_list', params: {lang:Lang, list:'products-metaldrumproduction-bidon'} }"
                              >{{Menu["products-metaldrumproduction-bidon"]}}</router-link>
                            </li>
                            <!-- <li class="has-children">
                              <a>{{Menu["products-metaldrumproduction-drumcap"]}}</a>
                              <ul class="dropdown">
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang} }"
                                  >{{Menu["products-metaldrumproduction-drumcap-subtopcover"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang} }"
                                  >{{Menu["products-metaldrumproduction-drumcap-unlogo"]}}</router-link>
                                </li>
                                <li>
                                  <router-link
                                    :to="{ name :'prod', params: {lang:Lang} }"
                                  >{{Menu["products-metaldrumproduction-drumcap-flans"]}}</router-link>
                                </li>
                              </ul>
                            </li>-->
                            <li>
                              <!-- <a>{{Menu["products-metaldrumproduction-drumcap"]}}</a> -->
                              <router-link
                                @click.native="$scrollToTop"
                                :to="{ name :'prod_list', params: {lang:Lang, list:'products-metaldrumproduction-drumcap'} }"
                              >{{Menu["products-metaldrumproduction-drumcap"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click.native="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang, product:'products-metaldrumproduction-drumquality' }}"
                              >{{Menu["products-metaldrumproduction-drumquality"]}}</router-link>
                            </li>
                          </ul>
                        </li>
                        <li class="has-children">
                          <!-- <a>{{Menu["products-drumibcrefurbishment"]}}</a> -->
                          <router-link
                            @click="$scrollToTop"
                            :to="{ name :'prod_list', params: {lang:Lang, list:'products-drumibcrefurbishment'} }"
                          >{{Menu["products-drumibcrefurbishment"]}}</router-link>
                          <ul class="dropdown">
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang, product:'products-drumibcrefurbishment-metal'} }"
                              >{{Menu["products-drumibcrefurbishment-metal"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang, product:'products-drumibcrefurbishment-plastic'} }"
                              >{{Menu["products-drumibcrefurbishment-plastic"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang, product:'products-drumibcrefurbishment-ibc'} }"
                              >{{Menu["products-drumibcrefurbishment-ibc"]}}</router-link>
                            </li>
                          </ul>
                        </li>
                        <li class="has-children">
                          <!-- <a>{{Menu["products-drumibcfilling"]}}</a> -->
                          <router-link
                            @click="$scrollToTop"
                            :to="{ name :'prod_list', params: {lang:Lang, list:'products-drumibcfilling'} }"
                          >{{Menu["products-drumibcfilling"]}}</router-link>
                          <ul class="dropdown">
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-drumibcfilling-drumibc'} }"
                              >{{Menu["products-drumibcfilling-drumibc"]}}</router-link>
                            </li>
                          </ul>
                        </li>
                        <li class="has-children">
                          <!-- <a>{{Menu["products-other"]}}</a> -->
                          <router-link
                            @click="$scrollToTop"
                            :to="{ name :'prod_list', params: {lang:Lang, list:'products-other'} }"
                          >{{Menu["products-other"]}}</router-link>
                          <ul class="dropdown">
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-other-plastic'} }"
                              >{{Menu["products-other-plastic"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-other-weldbreak'} }"
                              >{{Menu["products-other-weldbreak"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-other-conveyor'} }"
                              >{{Menu["products-other-conveyor"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-other-fixture'} }"
                              >{{Menu["products-other-fixture"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-other-paletizer'} }"
                              >{{Menu["products-other-paletizer"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-other-hydrolic'} }"
                              >{{Menu["products-other-hydrolic"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                @click="$scrollToTop"
                                :to="{ name :'prod', params: {lang:Lang,product:'products-other-machining'} }"
                              >{{Menu["products-other-machining"]}}</router-link>
                            </li>
                          </ul>
                        </li>
                        <li class="has-children">
                          <router-link
                            @click="$scrollToTop"
                            :to="{ name :'prod_list', params: {lang:Lang, list:'products-industrialauto'} }"
                          >{{Menu["products-industrialauto"]}}</router-link>
                          <ul class="dropdown">
                            <li>
                              <router-link
                                :to="{ name :'prod', params: {lang:Lang,product:'products-industrialauto-electricpano'} }"
                              >{{Menu["products-industrialauto-electricpano"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                :to="{ name :'prod', params: {lang:Lang,product:'products-industrialauto-software'} }"
                              >{{Menu["products-industrialauto-software"]}}</router-link>
                            </li>
                            <li>
                              <router-link
                                :to="{ name :'prod', params: {lang:Lang,product:'products-industrialauto-servorobotic'} }"
                              >{{Menu["products-industrialauto-servorobotic"]}}</router-link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <router-link
                            :to="{ name :'prod', params: {lang:Lang,product:'products-developmentconsultant'} }"
                          >{{Menu["products-developmentconsultant"]}}</router-link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a target="_blank" href="/catalog" >{{Menu["product-catalog"]}}</a>
                    </li>
                    <li>
                      <router-link :to="{ name :'videos', params: {lang:Lang} }">{{Menu.videos}}</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name :'references', params: {lang:Lang} }"
                      >{{Menu.references}}</router-link>
                    </li>
                    <li>
                      <router-link :to="{ name :'contact', params: {lang:Lang} }">{{Menu.contact}}</router-link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view style="top:115px;position:relative;" />
    <footer class="site-footer" style="top:115px;position:relative;">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="row">
              <div class="col-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <h3 class="footer-heading mb-4">{{Menu.corporate}}</h3>
                <ul class="list-unstyled">
                  <li>
                    <router-link
                      :to="{ name :'corp', params: {lang:Lang,subpage:'about'} }"
                    >{{Menu["corporate-about"]}}</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name :'corp', params: {lang:Lang,subpage:'vision-mission'} }"
                    >{{Menu["corporate-vision-mission"]}}</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name :'corp', params: {lang:Lang,subpage:'quality'} }"
                    >{{Menu["corporate-quality"]}}</router-link>
                  </li>
                  <li>
                    <router-link
                      :to="{ name :'corp', params: {lang:Lang,subpage:'info'} }"
                    >{{Menu["corporate-info"]}}</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div class="widget clearfix">
                  <div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2055.144161347187!2d29.396553554192398!3d40.7946493133202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9447062900ffe94b!2sG%C3%BCrteksan!5e0!3m2!1str!2str!4v1561302503543!5m2!1str!2str"
                      width="240"
                      height="240"
                      frameborder="0"
                      style="border:0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <!-- end clearfix -->
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="row mb-5">
              <div class="col-md-12">
                <h3 class="footer-heading mb-4">{{(Lang == 'tr' ? 'Türkiye' : 'Turkey')}}</h3>
              </div>
              <div class="col-md-6">
                <p>{{Globals.address}}</p>
              </div>
              <div class="col-md-6">
                <span class="icon-phone mr-2"></span>
                {{Globals.phoneNumber}}
                <br />
                <span class="icon-envelope mr-2"></span>
                {{Globals["e-mail"]}}
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-5 mt-5 text-center">
          <div class="col-md-12">
            <p>
              Copyright &copy; {{ThisYear}}{{( Lang == 'tr' ? ' Tüm hakları saklıdır.': ' All Rights Reserved')}}
              <i
                class="icon-heart-o"
                aria-hidden="true"
              ></i> by
              <a href="javascript:;" target="_blank">BurSoft</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
    <back-to-top bottom="50px" right="50px">
      <button type="button" style="background-color:#0695c7" class="btn btn-info btn-to-top">
        <span class="icon-arrow-up"></span>
      </button>
    </back-to-top>
  </div>
</template>

<script>
// import "flag-icon-css/css/flag-icon.min.css";
import { mapGetters, mapMutations } from "vuex";

// import "../src/theme/js/owl.carousel.min.js";

import BackToTop from "vue-backtotop";
import Vue from "vue";
Vue.use(BackToTop);

import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad);

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

export default {
  data() {
    let vm = this;
    return {
      selectedLanguage: vm.Lang
    };
  },
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang",
      Globals: "Globals"
    }),
    ThisYear() {
      return new Date().getFullYear();
    },
    Menu() {
      return this.LanguageMap.menu;
    }
  },
  methods: {
    ...mapMutations({
      setLanguage: "setLanguage"
    }),
    changeLanguage(lang) {
      return new Promise((res, rej) => {
        this.setLanguage(lang);
        let currentPathName = this.$route.name;
        let currentPathParams = this.$route.params;
        this.$router.push({
          name: currentPathName,
          params: { ...currentPathParams, lang: lang }
        });
        res();
      });
    },
    createMobileMenu() {
      const cloneMenu = function() {
        return new Promise(function(res, rej) {
          $(".site-mobile-menu-body").html("");
          $(".js-clone-nav").each(function() {
            var $this = $(this);
            $this
              .clone()
              .attr("class", "site-nav-wrap")
              .appendTo(".site-mobile-menu-body");
          });
          res();
        });
      };
      cloneMenu()
        .then(() => {
          var counter = 0;
          $(".site-mobile-menu .has-children").each(function() {
            var $this = $(this);

            $this.prepend('<span class="arrow-collapse collapsed">');

            $this.find(".arrow-collapse").attr({
              "data-toggle": "collapse",
              "data-target": "#collapseItem" + counter
            });

            $this.find("> ul").attr({
              class: "collapse",
              id: "collapseItem" + counter
            });

            counter++;
          });
          return true;
        })
        .then(function() {
          $("body").on("click", ".arrow-collapse", function(e) {
            var $this = $(this);
            if (
              $this
                .closest("li")
                .find(".collapse")
                .hasClass("show")
            ) {
              $this.removeClass("active");
            } else {
              $this.addClass("active");
            }
            e.preventDefault();
          });
        })
        .then(function() {
          $(window).resize(function() {
            var $this = $(this),
              w = $this.width();

            if (w > 768) {
              if ($("body").hasClass("offcanvas-menu")) {
                $("body").removeClass("offcanvas-menu");
              }
            }
          });
        })
        .then(function() {});
    }
  },
  watch: {
    selectedLanguage(val) {
      let vm = this;
      this.changeLanguage(val).then(function() {
        vm.createMobileMenu();
      });
    }
  },
  created() {
    let vm = this;
  },
  mounted() {
    let lastpage = JSON.parse(window.sessionStorage["page"]);
    if (lastpage.name != undefined) {
      this.$router.push({ ...lastpage });
      this.setLanguage(lastpage.params.lang);
    } else {
      this.$router.push({
        name: "home",
        params: { lang: this.Lang }
      });
    }
    this.selectedLanguage = this.Lang;
    this.createMobileMenu();

    console.log(".js-menu-toggle");
    $("body").on("click", ".js-menu-toggle", function(e) {
      var $this = $(this);
      e.preventDefault();

      if ($("body").hasClass("offcanvas-menu")) {
        $("body").removeClass("offcanvas-menu");
        $this.removeClass("active");
      } else {
        $("body").addClass("offcanvas-menu");
        $this.addClass("active");
      }
    });

    // click outisde offcanvas
    $(document).mouseup(function(e) {
      var container = $(".site-mobile-menu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if ($("body").hasClass("offcanvas-menu")) {
          $("body").removeClass("offcanvas-menu");
        }
      }
    });
  }
};
</script>

<style lang="scss">
.slide-one-item.home-slider .owl-prev:hover,
.slide-one-item.home-slider .owl-prev:focus,
.slide-one-item.home-slider .owl-next:hover,
.slide-one-item.home-slider .owl-next:focus {
  background: rgba(255, 255, 255, 0.8);
}

.flag-icon-medium {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 2.2em;
  margin: 0 0 0 15px;
}
.is-pointer {
  cursor: pointer;
}

.site-blocks-cover.inner-page,
.site-blocks-cover.inner-page .row {
  min-height: 100px;
  height: calc(25vh);
  /* background: deepskyblue; */
}

.site-blocks-cover h1 {
  text-transform: capitalize;
}

.site-footer {
  background: "#7f7f7f";
  background: rgba(0, 0, 0, 0.05);
}

.site-blocks-cover.overlay:before {
  background: rgba(0, 0, 0, 0.1);
}

.site-blocks-cover.overlay {
  background-size: 100% 100%;
}

.lang-select {
  // width: 80px;
  border: 0px;
  border-bottom: 1px solid gray;
  margin-left: 5px;
  text-align: right;
}

.lang-select:focus {
  border: 0px;
  border-bottom: 2px solid skyblue;
  outline: none;
}

.site-navbar-wrap {
  border-bottom: 2px solid #2ebbdd;
}

.site-navbar .site-navigation .site-menu > li > a {
  color: #000;
  font-weight: 600;

  &:hover {
    color: rgb(112, 112, 112);
  }
}
</style>