<template>
  <div>
    <div class="site-section" style="padding:0px;">
      <div class="slide-one-item owl-carousel">
        <div
          class="owl-slide"
          :style="{backgroundImage: 'url(images/slider/' + image.fileName + ')'}"
          data-stellar-background-ratio="0.5"
          v-for="(image,index) in Images"
        >
          <div class="container">
            <div class="row">
              <div
                class="col-12 d-flex align-items-center align-content-around flex-column"
                style="height:3rem;position:fixed;bottom:0;z-index:10;max-width:100% !important;width:100%;left:-15px;right:-15px"
              >
                <h2 style="color:black;" class="image-text">
                  <strong>{{image.header}}</strong>
                  <strong>{{(" " + image.content)}}</strong>
                </h2>
              </div>
            </div>
          </div>
          <!-- <div class="container">
            <div class="row">
              <div class="col-md-10">
                <h2 class="mb-5 mt-4" data-aos="fade-in" style="color:black;">
                  <strong>{{image.header}}</strong>
                  <br />
                  <strong>{{image.content}}</strong>
                </h2>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="site-section border-bottom bg-light pt-4 pb-4">
      <div class="col-12 text-center mb-4">
        <h3
          class="text-black h3 font-weight-bold text-uppercase"
        >{{(Lang == 'tr' ? Menu.products.replace(/[i]/g,'İ') :Menu.products )}}</h3>
      </div>
      <div
        class="row"
        style="margin:10px; display:flex; justify-content:space-around; flex-wrap:wrap"
      >
        <div v-for="cat in ProductCategories" :class="CategoryClass">
          <router-link @click.native="$scrollToTop" class="card zoom is-pointer" :to="cat.link">
            <div class="card-body">{{(Lang == 'tr' ? cat.name.replace(/[i]/g,'İ'): cat.name)}}</div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="site-section border-bottom">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-12 text-center">
            <h2 class="font-weight-light text-black display-4">{{AboutUs.header}}</h2>
          </div>
          <div class="col-md-7 text-center">
            <p style="font-size:1.3em">{{AboutUs.content}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="site-section border-bottom py-5 bg-light">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <h3 class="text-black h3 font-weight-bold text-uppercase">{{NumbersOfUs.text}}</h3>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-3">
                <span class="flaticon-worker display-3 text-primary"></span>
              </div>
              <div class>
                <h2 class="text-black">{{NumbersOfUs.employee_count}}</h2>
                <span v-html="NumbersOfUs.employee_count_text"></span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-3">
                <span class="flaticon-planet-earth display-3 text-primary"></span>
              </div>
              <div class>
                <h2 class="text-black">{{NumbersOfUs.country_count}}</h2>
                <span v-html="NumbersOfUs.country_count_text"></span>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4 mb-0 mb-lg-0">
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-3">
                <span class="flaticon-excavator display-3 text-primary"></span>
              </div>
              <div class>
                <h2 class="text-black">{{NumbersOfUs.project_count}}</h2>
                <span v-html="NumbersOfUs.project_count_text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="promo py-5 bg-primary" data-aos="fade">
      <div class="container text-center">
        <h2 class="d-block mb-0 font-weight-light text-white">
          <router-link
            class="text-white d-block"
            :to="{ name :'contact', params: {lang:Lang} }"
          >{{ContactUs.text}}</router-link>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";

import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel";
import "jquery.stellar/jquery.stellar.js";

import main from "../../src/theme/js/main.js";

export default {
  name: "home",
  components: {},
  computed: {
    ...mapGetters({
      LanguageMap: "LanguageMap",
      Lang: "Lang"
    }),
    Menu() {
      return this.LanguageMap.menu;
    },
    Images() {
      let sd = this.LanguageMap.home.slider;
      return [
        {
          fileName: "1.jpg",
          header: sd["1"].header,
          content: sd["1"].content,
          redirectBtn: false
        },
        {
          fileName: "2.jpg",
          header: sd["2"].header,
          content: sd["2"].content,
          redirectBtn: false
        },
        {
          fileName: "3.jpg",
          header: sd["3"].header,
          content: sd["3"].content,
          redirectBtn: false
        },
        {
          fileName: "4.jpg",
          header: sd["4"].header,
          content: sd["4"].content,
          redirectBtn: false
        },
        {
          fileName: "5.jpg",
          header: sd["5"].header,
          content: sd["5"].content,
          redirectBtn: false
        },
        {
          fileName: "6.jpg",
          header: sd["6"].header,
          content: sd["6"].content,
          redirectBtn: false
        }
      ];
    },
    CategoryClass() {
      let vm = this;
      let forLg = "col-xl-" + Math.floor(12 / vm.ProductCategories.length);
      //let forLg = "col-lg-" + Math.floor(12 / vm.ProductCategories.length);
      let forMd = "col-md-" + Math.ceil((12 / vm.ProductCategories.length) * 2);
      let forSm = "col-sm-" + Math.ceil((12 / vm.ProductCategories.length) * 3);
      let forXs = "col-" + Math.ceil((12 / vm.ProductCategories.length) * 6);
      return `${forLg} ${forMd} ${forSm} ${forXs}`;
    },
    ProductCategories() {
      let vm = this;
      return [
        {
          name: this.Menu["products-metaldrumproduction"],
          link: {
            name: "prod_list",
            params: { lang: this.Lang, list: "products-metaldrumproduction" }
          }
        },
        {
          name: this.Menu["products-drumibcrefurbishment"],
          link: {
            name: "prod_list",
            params: { lang: this.Lang, list: "products-drumibcrefurbishment" }
          }
        },
        {
          name: this.Menu["products-drumibcfilling"],
          link: {
            name: "prod_list",
            params: { lang: this.Lang, list: "products-drumibcfilling" }
          }
        },
        {
          name: this.Menu["products-other"],
          link: {
            name: "prod_list",
            params: { lang: this.Lang, list: "products-other" }
          }
        },
        {
          name: this.Menu["products-industrialauto"],
          link: {
            name: "prod_list",
            params: { lang: this.Lang, list: "products-industrialauto" }
          }
        },
        {
          name: this.Menu["products-developmentconsultant"],
          link: {
            name: "prod",
            params: {
              lang: this.Lang,
              product: "products-developmentconsultant"
            }
          }
        }
      ];
    },
    NumbersOfUs() {
      return this.LanguageMap.home.numbers_of_us;
    },
    AboutUs() {
      return this.LanguageMap.home.about;
    },
    ContactUs() {
      return this.LanguageMap.home.contact_us;
    }
  },
  methods: {
    createCarousel() {
      main($);
      let func = function(event) {
        //console.log(event);
      };
      $(".slide-one-item").off("changed.owl.carousel");
      $(".slide-one-item").on("changed.owl.carousel", func);
    }
  },
  created() {},
  mounted() {
    this.createCarousel();
  },
  watch: {
    Lang(val) {
      this.createCarousel();
    }
  }
};
</script>

<style scoped lang="scss">
$pri : #018DC0;
//$pri: #15a1cc;
//$pri: rgba(0, 191, 255, 0.5);
$black: #000;
$white: #fff;
$headerSize: 120px;

.slider-strong-weight {
  font-weight: 600;
  color: rgb(0, 0, 0);
}

.site-blocks-cover h1 {
  text-transform: none;
}

.zoom {
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
}

.card {
  // border: 1px solid $red;
  color: $black;
  margin: 5px 0px;
  text-align: center;
  border-radius: 0.5em;
  display: table;
  height: 150px;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .card {
    height: 100px;
  }
}

.card-header {
  background-color: $pri;
  color: $white;
  border-bottom: 0px;
}
.card-body {
  text-transform: uppercase;
  background-color: $pri;
  color: $white;
  font-size: 1.2em;
  font-weight: 900;
  border-radius: 0.5em;
  display: table-cell;
  vertical-align: middle;
  list-style-position: inside;
}
.card-footer {
  background-color: $pri;
  color: $white;
  text-align: right;
  border-top: 0px;
}

@keyframes delayedShow {
  to {
    visibility: visible;
  }
}

.slider-title {
  visibility: hidden;
  animation: 0s linear 1s forwards delayedShow;
}

body > div > div:nth-child(3) > div:nth-child(1) > div > div.owl-nav {
  bottom: 50%;
}

.owl-carousel {
  position: relative;
  height: calc(100% - 120px);

  div:not(.owl-controls) {
    height: calc(60vh - 120px);
  }

  .owl-slide {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 3rem;
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    .owl-prev {
      left: 5px;
      border: 1px solid black;
    }

    .owl-next {
      right: 5px;
    }
  }
}

@media (max-width: 1200px) {
  .owl-carousel .image-text {
    font-size: 1.6em;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .owl-carousel .image-text {
    font-size: 2em;
    width: 100%;
    text-align: center;
  }
}

div.owl-carousel > div.owl-nav > button.owl-prev {
  border: 1px solid black;
}

.owl-carousel .image-text:before {
  content: " ";
  position: absolute;
  width: 20px;
  height: 50px;
  animation: slide 3s linear infinite;
  transform: rotate(30deg);
}

@keyframes slide {
  from {
    left: -10px;
    background: rgba(255, 255, 255, 0.5);
  }
  to {
    left: 700px;
    background: rgba(255, 255, 255, 0.5);
  }
}
</style>